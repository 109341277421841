<template>
  <v-app-bar
    v-scroll="onScroll"
    :color="!isScrolling ? 'transparent' : 'white'"
    fixed
    flat
    height="130px"
  >
    <v-slide-x-transition>
      <v-img
        v-if="showLogo"
        :src="require('@/assets/logo.png')"
        class="shrink"
        contain
        height="130px"
      />
    </v-slide-x-transition>
    <v-spacer></v-spacer>
    <div class="text-no-wrap">
      <h2>
      <v-btn
        fab
        class="ma-2"
        color="info"
        :small="true"
      >
        <v-icon>
          mdi-phone
        </v-icon>
      </v-btn>
          (402) 630-0968
        </h2>
        <h3>
       <v-btn
        fab
        class="ma-2"
        color="info"
        :small="true"
      > <v-icon>
          mdi-email
        </v-icon>
      </v-btn>
        admin@streamlinepainting.biz
        </h3>
    </div>
  </v-app-bar>
</template>

<script>
  export default {
    name: 'CoreAppBar',

    components: {
      // SocialMedia: () => import('@/components/SocialMedia'),
    },

    data: () => ({
      showLogo: false,
      isScrolling: false,
    }),

    methods: {
      onScroll () {
        const offset = window.pageYOffset
        this.isScrolling = offset > 50
        this.showLogo = offset > 200
      },
    },
  }
</script>
